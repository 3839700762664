var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-4",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-card-title',[_vm._v(" Faktury "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.invoices,"sort-by":['number', 'created', 'expected'],"sort-desc":[false, true],"search":_vm.search},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'/uzytkownicy/' + item.user_id}},[_vm._v(" "+_vm._s(item.customer)+" ")])]}},{key:"item.price.net",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.net)+" zł ")]}},{key:"item.price.gross",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.gross)+" zł ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(_vm.getStatusName(item.status)))])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [(isNaN(parseInt(item.url)))?_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v("Zobacz fakturę")]):_vm._e(),(!isNaN(parseInt(item.url)))?_c('a',{attrs:{"href":_vm.media[item.url],"target":"_blank"}},[_vm._v("Zobacz fakturę")]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }