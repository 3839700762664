<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Faktury
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Szukaj" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="tableHeaders"
          :items="invoices"
          :sort-by="['number', 'created', 'expected']"
          :sort-desc="[false, true]"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:[`item.customer`]="{ item }">
            <a :href="'/uzytkownicy/' + item.user_id">
              {{ item.customer }}
            </a>
          </template>

          <template v-slot:[`item.price.net`]="{ item }"> {{ item.price.net }} zł </template>
          <template v-slot:[`item.price.gross`]="{ item }"> {{ item.price.gross }} zł </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getStatusColor(item.status)" dark>{{
              getStatusName(item.status)
            }}</v-chip>
          </template>
          <template v-slot:[`item.url`]="{ item }">
            <a v-if="isNaN(parseInt(item.url))" :href="item.url" target="_blank">Zobacz fakturę</a>
            <a v-if="!isNaN(parseInt(item.url))" :href="media[item.url]" target="_blank"
              >Zobacz fakturę</a
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import store from '@/store';
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';

  import axios from 'axios';

  export default {
    props: {
      source: String,
    },
    data: () => ({
      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      tableHeaders: [
        {
          text: 'Numer faktury',
          align: 'left',
          value: 'number',
        },
        {
          text: 'Data dodania',
          value: 'created',
        },
        {
          text: 'Data płatności',
          value: 'expected',
        },
        {
          text: 'Wartość netto',
          value: 'price.net',
        },
        {
          text: 'Wartość brutto',
          value: 'price.gross',
        },
        {
          text: 'Link do faktury',
          value: 'url',
        },
      ],
      invoices: [],
      media: [],
      search: null,
      isAdmin: store.getters.isAdmin,
    }),
    watch: {
      media: function (val) {
        val.forEach(element => {
          if (!isNaN(parseInt(element))) {
            this.getMedia();
          }
        });

        return;
      },
    },
    methods: {
      getMedia: function () {
        for (let [index, element] of this.media.entries()) {
          if (!isNaN(parseInt(element))) {
            axios({
              url: this.$store.state.url + '/api/media/pdf',
              data: {
                token: this.$store.state.token,
                file_id: element,
              },
              method: 'POST',
            }).then(resp => {
              let blob = this.base64ToBlob(resp.data.document, 'application/pdf');
              this.media[index] = URL.createObjectURL(blob);
              this.invoices.push({});
              this.invoices.pop();
            });
          }
        }
      },
      base64ToBlob: function (b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      },
    },
    created() {
      this.search = this.$route.params.search ? decodeURIComponent(this.$route.params.search) : null;

      /*if( this.isAdmin ){
          this.tableHeaders.splice(1, 0, {
            text: 'Klient',
            value: 'customer'
          })

          this.get_type = 'all'
        }*/

      let data = {
        token: this.$store.state.token,
      };

      if (this.$route.params.id)
        data = {
          token: this.$store.state.token,
          id: this.$route.params.id,
        };

      axios({
        url: this.$store.state.url + '/api/invoice/get-all',
        data: data,
        method: 'POST',
      }).then(resp => {
        for (let [i, t] of resp.data.invoices.entries()) {
          let d = new Date(Date.parse(t.date_created));
          let e = new Date(Date.parse(t.date_expected));
          let m = null;
          if (!isNaN(parseInt(t.invoice_url))) {
            m = this.media.length;

            this.media.push(t.invoice_url);
          }
          this.invoices.push({
            id: i + 1,
            number: t.invoice_number,
            price: {
              net: t.price_net,
              gross: t.price_gross,
            },
            created: d.toLocaleString('pl-PL'),
            expected: e.toLocaleString('pl-PL').substr(0, 10),
            url: m === null ? t.invoice_url : m,
          });
        }
      });
    },
  };
</script>
